import Footer from "@/layout/components/footer.component.tsx";
import Header from "@/layout/components/header.component.tsx";

import { Outlet } from "react-router-dom";

export function LandingLayout() {
  return (
    <>
      <div className="relative dark:opacity-20 mx-auto max-w-7xl"><div className="-top-44 -right-60 md:right-0 absolute bg-linear-115 from-[#fff1be] from-28% via-[#ee87cb] via-70% to-[#b060ff] blur-3xl rounded-full w-[36rem] h-60 transform-gpu rotate-[-10deg]"></div></div>
      <Header className="grow-0 landing-header" />
      <main
        className="flex [&::-webkit-scrollbar-thumb]:bg-neutral-300 [&::-webkit-scrollbar-track]:bg-neutral-100 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 [&::-webkit-scrollbar]:w-2 overflow-y-auto grow"
      >
        <Outlet />
      </main>
      <Footer className="grow-0" />
    </>
  );
}
