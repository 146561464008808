import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import router from "@/router.tsx";
import store from "@/store";

import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { TooltipProvider } from "@radix-ui/react-tooltip";

import "@/localization";
import "@/assets/styles/index.css";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <Provider store={store}>
      <TooltipProvider>
        <RouterProvider router={router} />
      </TooltipProvider>
    </Provider>
  </StrictMode>,
);
