import { cn } from '@/lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';
import { forwardRef, HTMLAttributes, Suspense } from 'react';

// https://developers.google.com/fonts/docs/material_symbols
// https://react.dev/blog/2024/12/05/react-19#support-for-stylesheets
// https://fonts.google.com/icons


const variants = cva('inline-block align-top material-symbols-outlined', {
  variants: {
    variant: {
      thin: 'font-light',
      normal: '',
      bold: 'font-semibold',
    },
    size: {
      sm: '',
      md: '',
      lg: 'text-[1.25em] align-text-bottom!',
    },
  },
  defaultVariants: {
    variant: 'normal',
    size: 'sm',
  },
});

type Props = HTMLAttributes<HTMLSpanElement> & VariantProps<typeof variants>;

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, variant, size, children, ...props }, ref) => {
    return (
      <Suspense fallback="loading...">
        <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" precedence='default' />
        <span className={cn(variants({ variant, size, className }))} {...props} ref={ref}>
          {children || 'info'}
        </span>
      </Suspense>
    );
  },
);

Component.displayName = 'Icon';
export { Component as Icon };
