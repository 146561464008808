import React, { ErrorInfo, ReactNode, useState } from "react";

interface ErrorBoundaryResult {
    ErrorBoundary: React.FC<{ children: ReactNode }>;
    didCatch: boolean;
    error: Error | null;
    errorInfo: ErrorInfo | null;
}

function useErrorBoundary(): ErrorBoundaryResult {
    const [error, setError] = useState<Error | null>(null);
    const [errorInfo, setErrorInfo] = useState<ErrorInfo | null>(null);
    const didCatch = Boolean(error);

    const resetErrorBoundary = () => {
        setError(null);
        setErrorInfo(null);
    }

    const ErrorBoundary: React.FC<{ children: ReactNode }> = ({ children }) => {
        // Render ErrorBoundaryCatch only if an error has NOT been caught
        if (!didCatch) {
            return (
                <ErrorBoundaryCatch error={error} setError={setError} setErrorInfo={setErrorInfo}
                    resetErrorBoundary={resetErrorBoundary}>
                    {children}
                </ErrorBoundaryCatch>
            );
        }

        // If an error has been caught, render the children directly 
        // (ErrorBoundaryCatch will handle rendering the fallback UI)
        return <>{children}</>;
    };

    return {
        ErrorBoundary,
        didCatch,
        error,
        errorInfo,
    };
}

// Separate component to catch errors and update state
interface ErrorBoundaryCatchProps {
    children: ReactNode;
    error: Error | null;
    setError: React.Dispatch<React.SetStateAction<Error | null>>;
    setErrorInfo: React.Dispatch<React.SetStateAction<ErrorInfo | null>>;
    resetErrorBoundary: () => void;
}

interface ErrorBoundaryCatchState {
    hasError: boolean;
}

class ErrorBoundaryCatch extends React.Component<
    ErrorBoundaryCatchProps,
    ErrorBoundaryCatchState
> {
    constructor(props: ErrorBoundaryCatchProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryCatchState {
        // Update state so the next render will show the fallback UI.
        console.error('getDerivedStateFromError', error);
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Log the error to an error reporting service here
        // console.error("Uncaught error:", error, errorInfo);
        this.props.setError(error);
        this.props.setErrorInfo(errorInfo);
    }

    componentDidUpdate(prevProps: ErrorBoundaryCatchProps) {
        // If the error prop is cleared, reset the hasError state
        if (prevProps.error !== null && this.props.error === null) {
            this.setState({ hasError: false });
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    <h2>Something went wrong.</h2>
                    <button onClick={this.props.resetErrorBoundary}>
                        Try again
                    </button>
                </div>
            );
        }

        return this.props.children;
    }
}

export default useErrorBoundary;