import { Button } from "@/components/ui/button";
import { storageLocal } from "@/lib/storage.utils";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function CookieBanner() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleAccept = () => {
    storageLocal.setItem("cookies", "true");
    setShow(false);
  };
  const handleOpen = useCallback(() => {
    const mouseMoveHandler = () => {
      setShow(true);
      clearTimeout(timeoutId); // Clear the timeout if mouse moves
      window.removeEventListener('mousemove', mouseMoveHandler); // Remove the event listener
    };
    const timeoutId = setTimeout(() => {
      window.addEventListener('mousemove', mouseMoveHandler); // Add mouse move event listener
    }, 1000);
  }, []);
  useEffect(() => {
    !Boolean(storageLocal.getItem("cookies")) && handleOpen();
  }, []);
  return show ? (
    <div className="top left z-50 absolute flex items-end bg-black/60 w-full h-full">
      <div className="bg-white w-full" role="region" aria-label="Cookie banner">
        <div
          role="alertdialog"
          aria-describedby="policy-text"
          aria-modal="true"
          aria-label={t("Cookies and use of our website")}
          className="flex justify-center items-center shadow-sm"
        >
          <div className="p-4 md:p-6 xl:container">
            <div className="flex items-stretch">
              <div className="grow">
                <h2 className="mb-2 font-semibold dark:text-black text-lg">
                  {t("Cookies and use of our website")}
                </h2>
                <div className="text-stone-600">
                  {t(
                    "We may use your information – collected through cookies and similar technologies - to improve your experience on our site, analyze how you use it and assist in our marketing efforts. We will handle your personal information in line with our",
                  )}
                  <Link
                    to="/privacy"
                    aria-label={t("More information about your privacy")}
                    className="mx-2 font-semibold dark:text-black"
                  >
                    {t("Privacy Policy")}
                  </Link>
                  {t("and")}
                  <Link
                    to="/cookies"
                    aria-label={t("More information about our cookie policy")}
                    className="mx-2 font-semibold dark:text-black"
                  >
                    {t("Cookie policy")}
                  </Link>
                </div>
              </div>
              <div className="flex items-center pl-4">
                <Button
                  id="cookie-accept"
                  onClick={handleAccept}
                  variant="outline"
                >
                  {t("Accept")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
