import { ErrorMessage } from "@/components/custom/error-message.component";
import Loader from "@/components/custom/loader.component.tsx";
import useErrorBoundary from "@/hooks/useErrorBoundary";
import CustomError from "@/models/CustomError.model.ts";
import { SerializedError } from "@reduxjs/toolkit";
import { PropsWithChildren, ReactElement } from "react";
import { useTranslation } from "react-i18next";


interface Props extends PropsWithChildren {
  isLoading?: boolean;
  isError?: boolean;
  // error: FetchBaseQueryError | SerializedError | undefined;
  error?:
  | { status: number; message: string | string[]; error: string }
  | SerializedError
  | undefined;
  loader?: ReactElement;
}

export function View({ isLoading, isError, error, children, loader }: Props) {
  // export function Page<T>({ data = null, children }: Props<T>) {
  const { t } = useTranslation();
  const {
    ErrorBoundary: Wrapper,
    didCatch,
    error: childError,
    errorInfo,
  } = useErrorBoundary();

  // console.log('View', didCatch, childError, errorInfo)

  // loading fallback
  if (isLoading)
    return loader || (
      <div className="flex flex-col justify-center items-center w-full h-full">
        <Loader />
      </div>
    );


  // error fallback
  if (isError) {
    const customError = new CustomError(error).toObject();
    return (
      <div className="flex flex-col justify-center items-center w-full h-full">
        <p>{t("Data loading error!")}</p>
        <ErrorMessage expended={true} {...customError} />
      </div>
    );
  }

  // error boundary fallback
  // Render Wrapper only when not loading and no error
  return (
    <Wrapper>
      {/* error boundary fallback */}
      {didCatch && childError && (
        <div className="flex flex-col justify-center items-center p-4 w-full h-full">
          <ErrorMessage
            status={0}
            name='Component Error'
            message={childError.message || ''}
            stack={childError.stack || errorInfo?.componentStack || ''}
            validations={[]}
            hidden={false}
            method={''}
            path={''}
            timestamp={''}
          />
        </div>
      )}

      {!didCatch && children}
    </Wrapper>)
}