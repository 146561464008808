export const Logo = ({
  className,
  isCompact = false,
}: {
  className?: string;
  isCompact?: boolean;
}) => (
  <svg
    x="0"
    y="0"
    shapeRendering="geometricPrecision"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox={isCompact ? "100 0 160 175" : "0 0 800 120"}
    xmlSpace="preserve"
    className={className}
  >
    <defs>
      <linearGradient id="logo-gradient" x1="0%" y1="0%" x2="300%" y2="0%">
        <stop offset="0%" stopColor="#14B8A6">
          <animate
            attributeName="stop-color"
            values="#14B8A6; #A855F7; #F97316"
            dur="4s"
            repeatCount="indefinite"
          />
        </stop>
        {/*<stop offset='50%' stopColor='#A855F7' />*/}
        <stop offset="100%" stopColor="#F97316">
          <animate
            attributeName="stop-color"
            values="#F97316; #A855F7; #14B8A6"
            dur="4s"
            repeatCount="indefinite"
          />
        </stop>
      </linearGradient>
      <clipPath id="logo-clip-path">
        <path d="M48.1,14.2c-10.9,0-20.8,3.8-28.8,10.1v-10.1L2.3,9.3v50.5c0,25.2,20.6,45.8,45.8,45.8s45.8-20.3,45.8-45.6S73.4,14.2,48.1,14.2ZM47.9,88.6c-15.9,0-28.6-12.7-28.6-28.6s12.7-28.6,28.6-28.6,28.6,12.7,28.6,28.6-12.7,28.6-28.6,28.6Z" />
        <path d="M179,14.2v10.1c-7.8-6.3-17.9-10.1-28.8-10.1-25.5,0-45.8,20.3-45.8,45.8s20.6,45.8,45.8,45.8,20.8-3.8,28.8-10.1v10.1l17-4.5V18.9l-17-4.7ZM178.6,60.3c-.1,15.7-12.8,28.3-28.6,28.3s-28.6-12.7-28.6-28.6,12.7-28.6,28.6-28.6,28.5,12.6,28.6,28.3c0,0,0,.2,0,.3s0,.2,0,.3Z" />
        <path d="M279.4,51.8h-54.7c0-.2,0-.4.2-.4,4.9-15,21-23.2,36-18.3,1.3.4,2.9,1.1,4,1.8h25.2c-5.6-8.3-13.6-14.7-23.9-18.1-23.9-8-49.8,5.1-57.6,29-.7,2-1.1,4-1.6,5.8-1.1,5.8-.9,11.6,0,17h72.2c-4.9,15-21,23.2-36,18.3-1.1-.2-2-.7-2.9-1.1h-25.7c5.6,8,13.6,14.3,23.5,17.4,24.1,7.8,49.8-5.4,57.6-29.5,1.3-4.5,2.2-8.9,2.2-13.4s-.2-6-.7-9.2l-17.9.7Z" />
        <path d="M354.2,14.2c-25.2,0-45.8,20.6-45.8,45.8s20.6,45.8,45.8,45.8,29.7-8,38-20.1h-25.2c-3.8,2-8.3,3.1-13,3.1-15.9,0-28.6-12.7-28.6-28.6s12.7-28.6,28.6-28.6,23.7,8.5,27.3,20.1h-36l5.6,17h48c.4-2.7.9-5.6.9-8.5s-.2-5.8-.9-8.5c-3.6-21.5-22.3-37.5-44.7-37.5Z" />
        <path d="M456.3,14.2c-25.2,0-45.8,20.3-45.8,45.8v45.8l11.4,4.9v-20.6c8.5,9.6,20.8,15.6,34.4,15.6,25.2,0,45.8-20.6,45.8-45.8s-20.6-45.8-45.8-45.8ZM456.3,94.4c-19,0-34.4-15.4-34.4-34.4s15.4-34.4,34.4-34.4,34.4,15.4,34.4,34.4-15.4,34.4-34.4,34.4Z" />
        <path d="M592.9,14v15.6c-8.5-9.6-20.8-15.6-34.4-15.6-25.2,0-45.8,20.6-45.8,45.8s20.6,45.8,45.8,45.8,26.1-6,34.4-15.6v15.6l11.4-4.5V18.5l-11.4-4.5ZM558.5,94.4c-19,0-34.4-15.4-34.4-34.4s15.4-34.4,34.4-34.4,34.4,15.4,34.4,34.4-15.4,34.4-34.4,34.4Z" />
        <path d="M661.9,14.2c-25.5,0-45.8,20.3-45.8,45.8h0v41.3l11.4,4.5h0v-45.8,1.1-1.1c0-19,15.4-34.4,34.4-34.4s27.5,9.4,32.2,22.6l10.9-3.6c-6.3-17.7-23.2-30.4-43.1-30.4Z" />
        <polygon points="759.8 14.2 759.8 14.2 748.4 14.2 748.4 14.2 708.4 14.2 708.4 25.6 748.4 25.6 748.4 96.9 759.8 105.6 759.8 105.6 759.8 25.6 791.1 25.6 800 14.2 800 14.2 759.8 14.2" />
      </clipPath>
    </defs>
    <rect
      width="1157.4"
      height="243"
      clipPath="url(#logo-clip-path)"
      className="fill-[#3F3F46] dark:fill-[url(#logo-gradient)]"
    />

  </svg>
);
