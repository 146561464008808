import { devMessage } from "@/lib/dev.utils.ts";
import { ErrorType, ValidationErrorExtendedDto } from "@/types/error.type";
import { SerializedError } from "@reduxjs/toolkit";

class CustomError extends Error {
  // error;
  status = 1;
  message = "";
  // message: string[] | string | undefined;
  timestamp = 0;
  method = "-";
  path = "-";
  stack = "-";
  validations = [] as ValidationErrorExtendedDto[];
  hidden = false;
  ttl = 3000;
  constructor(
    public _error?:
      | Error
      | ErrorType
      | { status: number; message: string | string[]; error: string }
      | SerializedError
      | undefined,
  ) {
    if (!_error) return;
    const error = ("data" in _error ? _error.data : _error) as ErrorType;

    // will throw when the response is not valid JSON
    if (typeof error !== "object") {
      throw new Error("error" in _error ? _error.error : String(error));
    }

    // console.log({ error });
    // if (typeof error !== 'object')
    //   throw new Error('Error is not an object!' + JSON.stringify(error));
    super(("message" in error && error.message) || "CustomError");
    if ("error" in error) {
      this.status = 503;
      this.name = "Fetch Error";
      this.message =
        "Server not response." + devMessage(" Check, the backend is up!");
    } else {
      this.status = ("status" in error && error.status) || 1;
      this.name = ("name" in error && error.name) || "Custom Error";
      this.message =
        ("message" in error && String(error.message)) || "Custom Error";
    }
    this.timestamp =
      ("timestamp" in error && Number(error.timestamp)) || new Date().getTime();
    this.stack = ("stack" in error && error.stack) || "-";
    this.path = ("path" in error && error.path) || "-";
    this.method = ("method" in error && error.method) || "";
    this.validations = ("validations" in error && error.validations) || [];
    this.hidden = ("hidden" in error && error.hidden) || false;
    this.ttl = Number(("ttl" in error && error.ttl) || 3000);
  }
  toObject() {
    return JSON.parse(JSON.stringify(this)) as ErrorType;
  }
}

export default CustomError;
