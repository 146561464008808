import { Trans, useTranslation } from "react-i18next";

function NoAccess() {
  const { t } = useTranslation();
  return (
    <div className="mx-auto rounded-3xl px-8 py-4 bg-red-200 sm:w-2/3 text-center">
      {t("You don`t have access to see the view!")}
    </div>
  );
}

// TODO  write a request role endpoint and  return requested roles.
function ShouldBeACustomer() {
  return (
    <div className="mx-auto rounded-3xl px-8 py-4 bg-red-200 sm:w-2/3 text-center">
      <Trans
        i18nKey="Should be a customer to see the page. <b/> Please, contact us become a customer via <a> business@midle.io </a>"
        components={{
          b: <br />,
          a: <a href="mailto:business@midle.io" />,
        }}
      />
    </div>
  );
}
function WaitingApprove() {
  return (
    <div className="mx-auto rounded-3xl px-8 py-4 bg-red-200 sm:w-2/3 text-center">
      <Trans
        i18nKey="Your account is pending for approvement. <b/> You can see the page when your account is approved!"
        components={{
          b: <br />,
        }}
      />
    </div>
  );
}

export const SecurityMessages = {
  NoAccess,
  ShouldBeACustomer,
  WaitingApprovement: WaitingApprove,
};
