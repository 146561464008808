import { Card, CardContent, CardDescription } from "@/components/ui/card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { HTMLAttributes, ReactElement, ReactNode } from "react";
import packageJSON from "../../../package.json";

const { version } = packageJSON;
const commitHash = import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA || "1234567890";
const commitAuthorName = import.meta.env.VITE_VERCEL_GIT_COMMIT_AUTHOR_NAME || "goker-dev";
const commitAuthorLogin = import.meta.env.VITE_VERCEL_GIT_COMMIT_AUTHOR_LOGIN || "goker";
const commitMessage = import.meta.env.VITE_VERCEL_GIT_COMMIT_MESSAGE || "first commit";
const commitRef = import.meta.env.VITE_VERCEL_GIT_COMMIT_REF || "main";
const repoSlug = import.meta.env.VITE_VERCEL_GIT_REPO_SLUG || "basepart";
const repoOwner = import.meta.env.VITE_VERCEL_GIT_REPO_OWNER || "goker-dev";
const provider = typeof import.meta.env.VITE_VERCEL_GIT_PROVIDER === "string" ? import.meta.env.VITE_VERCEL_GIT_PROVIDER : "github";


interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  icon?: ReactNode;
}

export default function Version({
  className = "",
  icon = <span role="img" aria-label="checkmark">✓</span>,
}: Props): ReactElement {
  const providerUrl = {
    "github": `https://github.com/`,
    "gitlab": `https://gitlab.com/`,
    "bitbucket": `https://bitbucket.org/`,

  }[provider] || "https://github.com/";
  return (
    <div
      data-cy="Version"
      className={`z-50 fixed bottom-0 right-0 bg-neutral-light text-neutral-dark p-0.5 
      rounded-tl-lg text-xs flex justify-between items-center tracking-wide opacity-80
     ${className}`}
      style={{ fontSize: ".7rem" }}
    >
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <span className="mr-0.5">{icon}</span>{version}</TooltipTrigger>
          <TooltipContent className="mr-2 p-0">
            <Card className="p-2 border-none font-mono text-xs">
              <CardDescription className="text-[0.6rem] uppercase">Package version: {version}</CardDescription>
              <CardContent className="p-0">
                <dl className="[&_dd]:inline-block [&_dt]:inline-block [&_dt]:w-16 [&_dd]:before:content-[':']">
                  <h4 className="flex items-center text-[0.6rem] text-muted-foreground uppercase">Project</h4>
                  <dt>Repo</dt>
                  <dd><a href={`${providerUrl}/${repoOwner}/${repoSlug}`} target="_blank" rel="noopener noreferrer">{repoSlug}</a></dd>
                  <div />
                  <dt>Owner</dt>
                  <dd><a href={`${providerUrl}/${repoOwner}`} target="_blank" rel="noopener noreferrer">{repoOwner}</a></dd>
                  <h4 className="text-[0.6rem] text-muted-foreground uppercase">Commit</h4>
                  <dt>Author</dt>
                  <dd><a href={`${providerUrl}/${commitAuthorLogin}`} target="_blank" rel="noopener noreferrer">{commitAuthorName}</a></dd>
                  <div />
                  <dt>Build</dt>
                  <dd><a href={`${providerUrl}/${repoOwner}/${repoSlug}/commit/${commitRef}`} target="_blank" rel="noopener noreferrer">{commitRef}</a></dd>
                  <div />
                  <dt>Message</dt>
                  <dd><a href={`${providerUrl}/${repoOwner}/${repoSlug}/commit/${commitHash}`} target="_blank" rel="noopener noreferrer">{commitMessage}</a></dd>
                </dl>
                <div className="pt-2 text-right">
                  <h4 className="text-[0.6rem] text-muted-foreground uppercase">
                    <span role="img" aria-label="checkmark" className="text-xs">✓</span> Trust the developer
                  </h4>
                </div>
              </CardContent>
            </Card>
          </TooltipContent>
        </Tooltip >
      </TooltipProvider >
    </div >
  );
}
