import { useTranslation } from "react-i18next";
import { isRouteErrorResponse, Link, useRouteError } from "react-router-dom";

type Props = {
  status?: number;
  name?: string;
  message?: string;
  stack?: object;
};
export function ErrorView(props: Props) {
  const error: Props | Error = useRouteError() || props;
  const { t } = useTranslation();

  if (
    isRouteErrorResponse(error) ||
    [401, 404, 418, 503].includes(Number(error?.status))
  ) {
    return (
      <div className="flex justify-center items-center w-full min-h-screen">
        <div className="text-center">
          <div id="error-code" className="mb-4 text-5xl">
            {error?.status}
          </div>
          <div className="mb-4 text-3xl">
            {
              (error?.status === 404) ? t("Page not found!") :
                (error?.status === 401) ? t("You aren`t authorized to see this") :
                  (error?.status === 503) ? t("Looks like our API is down") :
                    (error?.status === 418) ? t("Tea time!") :
                      t("Unknown error")
            }
          </div>
          <div className="">
            <Link to="/" className="button">
              <i className="icon-left-open" />
              {t("Go back to home")}
            </Link>
          </div>
        </div>
      </div>
    );
  }
  if (error instanceof Error)
    return (
      <div className="p-2">
        <div
          className="flex flex-col border-2 border-destructive/80 bg-red-200/90 mx-auto px-5 py-3 rounded-md max-w-full"
        >
          <div className="flex justify-between space-x-2 text-sm">
            <div className="flex space-x-2">
              <div className="font-semibold text-destructive">{error?.name}d</div>
              <div className="font-light">{error?.message}</div>
            </div>
          </div>
          <div className="pt-2 font-mono text-xs">
            <label className="font-semibold">{t("Stack:")}</label>
            <pre className="overflow-auto">{error?.stack}</pre>
          </div>
        </div>
        <p>Catched by useRouteError() in Error.view.tsx</p>
      </div>
    );
  else return <div>{JSON.stringify(error, null, 2)}</div>;
}
