import { selectData, setData } from "@/store/system.slice";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useSystem = () => {
  const dispatch = useDispatch();
  // const { isOpen, handleVerify: _handleVerify, timestamp } = useSelector(selectVerification);
  const data = useSelector(selectData);
  // console.log({ data });

  // const handleVerification = useCallback((timestamp: number, handleVerify: VerifyHandler) => {
  //   dispatch(setVerification({ isOpen: true, handleVerify, timestamp }));
  // }, []);
  //
  // const closeVerification = useCallback(() => {
  //   dispatch(setVerification({ isOpen: false, handleVerify: async () => true, timestamp: 0 }));
  // }, []);
  //
  // const handleVerify = useCallback(
  //   (data: { code: string }, setError: ReformSetError) => {
  //     console.log('handleVerify', {});
  //     _handleVerify(data, setError)
  //       .then(() => closeVerification())
  //       .catch((error) => errorHandler(error, setError));
  //   },
  //   [_handleVerify, closeVerification],
  // );
  const handleData = useCallback(
    (data: Record<string, string | number | boolean | undefined>) => {
      dispatch(setData(data));
    },
    [],
  );

  return {
    data,
    setData: handleData,
    // handleVerification,
    // closeVerification,
    // isOpen,
    // timestamp,
    // handleVerify,
  };
};
