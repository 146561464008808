const IS_PRODUCTION = import.meta.env.PROD;

interface Props {
  code: string
}

export default function GoogleAnalytics({ code = '' }: Props) {
  return (IS_PRODUCTION && <>
    <script async src={`https://www.googletagmanager.com/gtag/js?id=${code}`}></script>
    <script
      dangerouslySetInnerHTML={{
        __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${code}', {
                  page_path: window.location.pathname
                });
              `,
      }}
    />
  </>
  );
}
