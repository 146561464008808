import { selectAuth } from '@/store/auth.slice';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useAuth = () => {
  const auth = useSelector(selectAuth);
  const isAuthenticated = !!auth.name;

  const role = Array.isArray(auth?.roles) ? auth?.roles[0] : '-';

  return useMemo(
    () => ({
      ...auth,
      isAuthenticated,
      role,
    }),
    [auth],
  );
};
