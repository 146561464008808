"use client"

import * as React from "react"

// import { NavMain } from "@/components/nav-main"
// import { NavProjects } from "@/components/nav-projects"
// import { NavUser } from "@/components/nav-user"
// import { TeamSwitcher } from "@/components/team-switcher"
import {
    Sidebar,
    SidebarContent,
    SidebarFooter,
    SidebarHeader,
    SidebarRail
} from "@/components/ui/sidebar"
import { NavLink } from "react-router-dom"
import { Logo } from "./logo.component"
import { NavMain } from "./nav-main.component"
import { NavUser } from "./nav-user.component"

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {

    return (
        <Sidebar collapsible="icon" {...props}>
            <SidebarHeader className="flex justify-center items-center -mb-4">
                <NavLink to="/dashboard" aria-label="logo" className="group-data-[state=collapsed]:w-10 overflow-hidden">
                    <Logo
                        className='hover:group-data-[state=collapsed]:scale-[1.07] group-data-[state=collapsed]:origin-[20%_50%] fill-primary h-14 hover:scale-[1.05] origin-[50%_50%] transition-all group-data-[state=collapsed]:-translate-x-[44px] duration-500 ease-in-out'
                    />
                </NavLink>
                {/* <TeamSwitcher teams={data.teams} /> */}
            </SidebarHeader>
            <SidebarContent>
                <NavMain />
            </SidebarContent>
            <SidebarFooter>
                <NavUser />
            </SidebarFooter>
            <SidebarRail />
        </Sidebar>
    )
}
