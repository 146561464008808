import type { RootState } from "@/store";
import { AuthDto, coreApi as api } from "@/store/core.api.ts";
import { storageLocal } from "@/lib/storage.utils";
import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "auth",
  initialState: {
    name: storageLocal.getItem("name"),
    roles: storageLocal.getItem("roles")?.split(","),
    session: storageLocal.getItem("session"),
    accessToken: storageLocal.getItem("access"),
    refreshToken: storageLocal.getItem("refresh"),
  } as AuthDto,
  reducers: {
    auth: (state, { payload }) => {
      if (!payload.accessToken) return;
      state.name = payload.name;
      state.roles = payload.roles;
      state.session = payload.session;
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      // TODO write on cookies
      storageLocal.setItem("name", payload.name);
      storageLocal.setItem("roles", payload.roles.join(","));
      storageLocal.setItem("session", payload.session);
      storageLocal.setItem("access", payload.accessToken);
      storageLocal.setItem("refresh", payload.refreshToken);
    },
    changeName: (state, { payload }) => {
      state.name = payload.name;
      storageLocal.setItem("name", payload.name);
    },
    logout: (state) => {
      console.log("logout", { state });
      state.name = "";
      state.roles = [];
      state.session = "";
      state.accessToken = "";
      state.refreshToken = "";
      // TODO delete on cookies
      storageLocal.removeItem("name");
      storageLocal.removeItem("roles");
      storageLocal.removeItem("session");
      storageLocal.removeItem("access");
      storageLocal.removeItem("refresh");
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      ({ type, meta }) => {
        return (
          !!type.match("fulfilled") &&
          [
            "authControllerRegister",
            "authControllerLogin",
            "authControllerVerify",
          ].includes(meta?.arg?.endpointName)
        );
      },
      (state, { type, ...action }) => {
        const { payload } = action as { payload: AuthDto };
        console.log(" builder.addMatcher setCredentials", {
          payload,
          state,
        });
        slice.caseReducers.auth(state, { payload, type });
      },
    );
    builder.addMatcher(
      api.endpoints.authControllerLogout.matchFulfilled,
      (state) => {
        console.log(" builder.addMatcher logout", { state });
        slice.caseReducers.logout(state);
      },
    );
  },
});

export const { auth, changeName, logout } = slice.actions;

export default slice.reducer;

export const selectAuth = (state: RootState): AuthDto => state.auth;
