import { removeMessage } from "@/store/system.slice";
import { MessageType } from "@/types/message.type";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

export default function Message({ message, link, linkText }: MessageType) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => dispatch(removeMessage());

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black/10 z-20 ">
      <div
        className="p-4 md:p-8 border-2 border-neutral-100 rounded-2xl bg-white shadow-xl max-w-full md:w-1/2
        flex flex-col"
      >
        <div
          className="flex space-x-2 justify-between text-sm"
          onClick={() => setShow(!show)}
        >
          <div className="text-base font-semibold">
            {message}
            {link && (
              <Link
                className="mt-4 block underline text-sm font-normal"
                to={link}
                onClick={handleClose}
              >
                {linkText || "click here"}
              </Link>
            )}
          </div>
          <button className="text-xs" onClick={handleClose}>
            {t("CLOSE")}
          </button>
        </div>
      </div>
    </div>
  );
}
