import { cn } from "@/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";
import { SVGAttributes } from "react";

const variants = cva("", {
  variants: {
    variant: {
      primary: "fill-primary",
      secondary: "fill-secondary",
      destructive: "fill-destructive",
      muted: "fill-muted",
    },
    size: {
      lg: "w-24",
      md: "w-16",
      sm: "w-10",
      xs: "w-8",
    },
  },
  defaultVariants: {
    variant: "primary",
    size: "md",
  },
});

type Props = SVGAttributes<SVGElement> & VariantProps<typeof variants>;

export const Loader = ({ className, variant, size, ...props }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="22 22 54 54"
      preserveAspectRatio="xMidYMid"
      className={cn(variants({ variant, size, className }))}
      {...props}
    >
      <g>
        <defs>
          <filter
            colorInterpolationFilters="sRGB"
            height="300%"
            width="300%"
            y="-100%"
            x="-100%"
            id="$id-filter"
          >
            <feGaussianBlur
              stdDeviation="2.4000000000000004"
              in="SourceGraphic"
            ></feGaussianBlur>
            <feComponentTransfer result="cutoff">
              <feFuncA
                tableValues="0 0 0 0 0 0 1 1 1 1 1"
                type="table"
              ></feFuncA>
            </feComponentTransfer>
          </filter>
        </defs>
        <g filter="url(#$id-filter)">
          <g transform="translate(50 50)">
            <g>
              <circle r="5" cy="0" cx="17">
                <animate
                  begin="-0.25s"
                  repeatCount="indefinite"
                  dur="4s"
                  values="3.5999999999999996;8.399999999999999;3.5999999999999996"
                  keyTimes="0;0.5;1"
                  attributeName="r"
                ></animate>
              </circle>
              <animateTransform
                begin="0s"
                repeatCount="indefinite"
                dur="4s"
                values="0;360"
                keyTimes="0;1"
                type="rotate"
                attributeName="transform"
              ></animateTransform>
            </g>
          </g>
          <g transform="translate(50 50)">
            <g>
              <circle r="5" cy="0" cx="17">
                <animate
                  begin="-0.2s"
                  repeatCount="indefinite"
                  dur="2s"
                  values="3.5999999999999996;8.399999999999999;3.5999999999999996"
                  keyTimes="0;0.5;1"
                  attributeName="r"
                ></animate>
              </circle>
              <animateTransform
                begin="-0.05s"
                repeatCount="indefinite"
                dur="2s"
                values="0;360"
                keyTimes="0;1"
                type="rotate"
                attributeName="transform"
              ></animateTransform>
            </g>
          </g>
          <g transform="translate(50 50)">
            <g>
              <circle r="5" cy="0" cx="17">
                <animate
                  begin="-0.15s"
                  repeatCount="indefinite"
                  dur="1.3333333333333333s"
                  values="3.5999999999999996;8.399999999999999;3.5999999999999996"
                  keyTimes="0;0.5;1"
                  attributeName="r"
                ></animate>
              </circle>
              <animateTransform
                begin="-0.1s"
                repeatCount="indefinite"
                dur="1.3333333333333333s"
                values="0;360"
                keyTimes="0;1"
                type="rotate"
                attributeName="transform"
              ></animateTransform>
            </g>
          </g>
          <g transform="translate(50 50)">
            <g>
              <circle r="5" cy="0" cx="17">
                <animate
                  begin="-0.1s"
                  repeatCount="indefinite"
                  dur="1s"
                  values="3.5999999999999996;8.399999999999999;3.5999999999999996"
                  keyTimes="0;0.5;1"
                  attributeName="r"
                ></animate>
              </circle>
              <animateTransform
                begin="-0.15s"
                repeatCount="indefinite"
                dur="1s"
                values="0;360"
                keyTimes="0;1"
                type="rotate"
                attributeName="transform"
              ></animateTransform>
            </g>
          </g>
          <g transform="translate(50 50)">
            <g>
              <circle r="5" cy="0" cx="17">
                <animate
                  begin="-0.05s"
                  repeatCount="indefinite"
                  dur="0.8s"
                  values="3.5999999999999996;8.399999999999999;3.5999999999999996"
                  keyTimes="0;0.5;1"
                  attributeName="r"
                ></animate>
              </circle>
              <animateTransform
                begin="-0.2s"
                repeatCount="indefinite"
                dur="0.8s"
                values="0;360"
                keyTimes="0;1"
                type="rotate"
                attributeName="transform"
              ></animateTransform>
            </g>
          </g>
        </g>
        <g></g>
      </g>
    </svg>
  );
};
