

import { Logo } from "@/components/custom/logo.component";
import { useNavigation } from "@/hooks";
import Version from "@/layout/components/version.component.tsx";
import { cn } from "@/lib/utils";
import { Github, Instagram, Linkedin } from "lucide-react";
import { NavLink } from "react-router-dom";
interface Props {
  className: string;
}

export default function Footer({ className = "" }: Props) {
  const navigation = useNavigation("footer");

  return (
    <footer className={cn("layout-footer text-sm flex items-center justify-between max-w-3xl w-full mx-auto", className)}>
      <div className="flex md:flex-row flex-col md:items-center gap-x-8 pl-4 md:pl-0 font-display"><div className="flex items-center gap-x-2 text-muted-foreground"><Logo className="h-4" /> &copy; 2025</div>

        <div className="flex sm:flex-row flex-col items-start gap-x-4 gap-y-1.5 mt-2 sm:mt-0 text-xs">
          <a href="https://linkedin.com/company/basepart" target="_blank" rel="noopener noreferrer" title="BASEPART on LinkedIn" className="flex items-center gap-x-1">
            <Linkedin className="w-4 h-4" /> <span className="sm:hidden">LinkedIn</span>
          </a>
          <a href="https://github.com/basepart" target="_blank" rel="noopener noreferrer" title="BASEPART on GitHub" className="flex items-center gap-x-1">
            <Github className="w-4 h-4" /> <span className="sm:hidden">GitHub</span>
          </a>
          <a href="https://instagram.com/basepart" target="_blank" rel="noopener noreferrer" title="BASEPART on Instagram" className="flex items-center gap-x-1">
            <Instagram className="w-4 h-4" /> <span className="sm:hidden">Instagram</span>
          </a>
        </div>
      </div>
      <div className="flex sm:flex-row flex-col justify-center sm:items-center gap-x-0 gap-y-2.5 sm:gap-y-0 sm:gap-x-6 p-4 text-xs">
        {navigation.map((i) => (
          <NavLink to={i.to} key={i.to}>
            {i.text}
          </NavLink>
        ))}
      </div>
      <Version />
    </footer>
  );
}
