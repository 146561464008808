import { useSystem } from '@/hooks/useSystem.hook';
import { selectAuth } from '@/store/auth.slice';
import {
  ApplicationDto,
  ProjectDto,
  useAuthControllerAccountInfoQuery,
  useProjectsApplicationsControllerFindAllByProjectIdQuery,
  useProjectsControllerFindAllQuery,
} from '@/store/core.api';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useAccount = () => {
  const auth = useSelector(selectAuth);

  const isAuthenticated = !!auth.name;
  const { data: info, isLoading: isInfoLoading, isError: isInfoError, error: infoError } = useAuthControllerAccountInfoQuery(undefined, { skip: !isAuthenticated });

  const name = info?.name;
  const username = info?.username;
  const role = Array.isArray(auth?.roles) ? auth?.roles[0] : '-';


  // get data from store || storage
  const { data, setData } = useSystem();
  const projectId = data?.project;
  const appId = data?.app;

  const { data: projects, isLoading: isProjectsLoading, isError: isProjectsError, error: projectsError } = useProjectsControllerFindAllQuery(
    {
      limit: 50,
      page: 1,
    },
    { skip: !isAuthenticated },
  );


  // // set default project
  // const getDefaultProject = useCallback(() => {
  //   if (projects?.list) {
  //     const _project = projectId
  //       ? projects?.list?.find((i) => i.id === projectId)
  //       : projects?.list[0];
  //     return _project?.id;
  //   }
  // }, [projects]);

  // populate project data
  const project = (projects?.list?.find((i) => i.id === projectId) || projects?.list[0]) as ProjectDto;

  const { data: apps, isLoading: isAppsLoading, isError: isAppsError, error: appsError } = useProjectsApplicationsControllerFindAllByProjectIdQuery(
    {
      id: project?.id,
      page: 1,
      limit: 50,
    },
    { skip: !project?.id },
  );

  // // set default app
  // const getDefaultApp = useCallback(() => {
  //   if (apps?.list) {
  //     const _app = appId ? apps?.list?.find((i) => i.id === appId) : apps?.list[0];
  //     return _app?.id;
  //   }
  // }, [projects, apps]);

  const app = (apps?.list?.find((i) => i.id === appId) || apps?.list[0]) as ApplicationDto;

  const handleProject = useCallback(
    (id: string) => {
      const _project = projects?.list?.find((i) => i.id === id);
      if (projects) setData({ project: _project?.id, app: undefined });
    },
    [projects],
  );

  const handleApp = useCallback(
    (id: string) => {
      console.log('handleApp', id);
      const _app = apps?.list?.find((i) => i.id === id);
      if (_app) setData({ app: _app.id });
    },
    [apps],
  );

  // console.log(auth, info, project?.id, app?.id);

  const isLoading = isInfoLoading || isProjectsLoading || isAppsLoading;
  const isError = isInfoError || isProjectsError || isAppsError;
  const error = infoError || projectsError || appsError;

  const initialize = useCallback(() => {
    handleProject(project?.id);
    handleApp(app?.id);
  }, [projects, apps]);


  return useMemo(
    () => ({
      isLoading,
      isError,
      error,
      initialize,
      ...auth,
      name,
      role,
      username,
      projects: projects?.list,
      project,
      projectId: project?.id,
      apps: apps?.list,
      app,
      handleProject,
      handleApp,
    }),
    [isLoading, isError, error, auth, info, project?.id, app?.id],
  );
};
