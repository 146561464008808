import Error from "@/layout/components/error.component.tsx";
import Message from "@/layout/components/message.component.tsx";
import { RootState } from "@/store";
import { selectErrors, selectMessages } from "@/store/system.slice";
import { ErrorType } from "@/types/error.type";
import { MessageType } from "@/types/message.type";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Log from "./log.component.tsx";

function System({
  errors,
  messages,
}: {
  errors: ErrorType[];
  messages: MessageType[];
}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    function handleLogs(e: KeyboardEvent) {
      if (e.key === "l" && e.ctrlKey) setShow(!show);
    }

    document.addEventListener("keyup", handleLogs);
    return () => {
      document.removeEventListener("keyup", handleLogs);
    };
  });
  return (
    <>
      <div
        className={"fixed top-0 left-0 w-full z-50 h-1 space-y-2 "}
        style={{ backgroundSize: "200% 400%" }}
      >
        {errors.map(
          (i, k) =>
            (Number(i?.status) >= 500 || !i?.method || i?.method === "GET") &&
            !i.hidden && <Error key={k} {...i} removable />,
        )}
      </div>
      {!!messages.length && <Message {...messages[0]} />}
      {show && <Log errors={errors} />}
    </>
  );
}

export default connect((state: RootState) => ({
  errors: selectErrors(state),
  messages: selectMessages(state),
}))(System);
