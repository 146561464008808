import { TimedCloseButton } from "@/components/generic/timed-close.component.tsx";
import { hideError } from "@/store/system.slice";
import { ErrorType } from "@/types/error.type";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

export default function Error({
  status = 1000,
  name,
  message,
  stack,
  method,
  path,
  timestamp,
  removable = false,
  expended = false,
  validations = [],
  ttl = 0,
}: ErrorType) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [pause, setPause] = useState(false);
  const handleClose = () => {
    // console.log('handleClose', { timestamp });
    dispatch(hideError({ timestamp }));
  };
  // console.log({ message });

  return (
    <div
      className={`px-5 py-3 border-2 rounded-md max-w-full xl:w-1/2 mx-auto
        flex flex-col
        ${status >= 500 && "border-red-500/80 bg-red-200/90 dark:bg-red-500/50"}
        ${status < 500 && status >= 400 && "border-amber-500/80 bg-amber-200/90"}
        ${status < 400 && status > 0 && "border-neutral-500/80 bg-white/90"}
        ${status === 0 && "border-red-500/80 bg-red-200/90 dark:bg-red-500/50"}
        `}
    >
      <div
        className="flex justify-between space-x-2 text-sm"
        onClick={() => setShow(!show)}
        onMouseEnter={() => setPause(true)}
        onMouseLeave={() => setPause(false)}
      >
        <div className="flex flex-wrap sm:flex-nowrap items-center gap-2">
          <div className="font-semibold text-red-800 dark:text-black">
            {status ? status : "App"}
          </div>
          <div className="font-light text-red-800 dark:text-black">{name}</div>
          <div className="font-light">{message}</div>
        </div>
        {removable && (
          <TimedCloseButton
            timer={Number(ttl)}
            pause={pause}
            className="p-.5 rounded-full font-semibold hover:text-red-600 text-lg"
            onClick={handleClose}
          >
            <i className="icon-cancel" />
          </TimedCloseButton>
        )}
      </div>
      <div className="text-xs">
        {validations?.map((i) => (
          <div key={i.property}>
            <strong className="mr-2">{i.property}</strong>
            {/*{i?.constraints?.matches}*/}
          </div>
        ))}
      </div>
      {(expended || show) && (
        <div className='pt-2 font-mono text-xs'>
          <label className='font-semibold'>{t('Stack:')}</label>
          <pre
            className='[&::-webkit-scrollbar-thumb]:bg-stone-300 [&::-webkit-scrollbar-track]:bg-stone-100 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 [&::-webkit-scrollbar]:w-2 overflow-auto'
          >
            {stack}
          </pre>
          <div className='pt-2'>
            <strong>{method}</strong>:{path}
          </div>
        </div>
      )}
    </div>
  );
}
