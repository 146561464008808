import authReducer from "@/store/auth.slice";
import systemReducer from "@/store/system.slice";
import { configureStore } from "@reduxjs/toolkit";
import { coreApi as api } from "./core.api";

const store = configureStore({
  reducer: {
    system: systemReducer,
    [api.reducerPath]: api.reducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
  devTools: true,
});

// export type RootState = ReturnType<any>;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
