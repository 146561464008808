import { useSystem } from "@/hooks/useSystem.hook.ts";
import { useCallback, useEffect } from "react";

export const useTheme = () => {
  const { data, setData } = useSystem();

  const theme = String(data?.theme || "").replace('undefined', "");

  const toggleTheme = useCallback(
    (t: string) => {
      setData({ theme: t });
      toggleClass(t);
    },
    [theme, setData],
  );

  const toggleClass = useCallback((theme: string) => {
    if (theme === "dark") document.documentElement.classList.add(theme);
    else
      document.documentElement.classList.remove(
        ...document.documentElement.classList,
      );
  }, []);

  useEffect(() => {
    toggleTheme(theme ||
      (window?.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"));
  }, []);


  return { theme, setTheme: toggleTheme };
};
