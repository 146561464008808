"use client"

import {
    ChevronsUpDown
} from "lucide-react"

import {
    Avatar,
    AvatarFallback,
    AvatarImage,
} from "@/components/ui/avatar"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
    useSidebar,
} from "@/components/ui/sidebar"
import { useAccount, useNavigation } from "@/hooks"
import { Link } from "@/types/link.type"
import { NavLink } from "react-router-dom"
import { Fragment } from "react/jsx-runtime"
import { ThemeSwitcher } from "../generic/theme-switcher.component"

export function NavUser() {
    const { isMobile } = useSidebar()
    const { name } = useAccount()
    const navigation = useNavigation("account")

    return (
        <SidebarMenu>
            <SidebarMenuItem>
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <SidebarMenuButton
                            size="lg"
                            className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                        >
                            <Avatar className="rounded-full w-8 h-8">
                                <AvatarImage src={''} alt={name} />
                                <AvatarFallback className="border rounded-full font-semibold text-xl">{name?.charAt(0)}</AvatarFallback>
                            </Avatar>
                            <div className="flex-1 grid text-sm text-left leading-tight">
                                <span className="font-semibold truncate">{name}</span>
                                <span className="text-xs truncate">{''}</span>
                            </div>
                            <ChevronsUpDown className="ml-auto size-4" />
                        </SidebarMenuButton>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                        className="ml-2 rounded-lg w-(--radix-dropdown-menu-trigger-width) min-w-56"
                        side={isMobile ? "bottom" : "top"}
                        align="end"
                        sideOffset={4}
                    >
                        <DropdownMenuLabel className="p-0 font-normal">
                            <div className="flex items-center gap-2 px-1 py-1.5 text-sm text-left">
                                <Avatar className="rounded-full w-8 h-8">
                                    <AvatarImage src={''} alt={name} />
                                    <AvatarFallback className="border rounded-full font-semibold text-xl">{name?.charAt(0)}</AvatarFallback>
                                </Avatar>
                                <div className="flex-1 grid text-sm text-left leading-tight">
                                    <span className="font-semibold truncate">{name}</span>
                                    <span className="text-xs truncate">{''}</span>
                                </div>
                                <ThemeSwitcher />
                            </div>
                        </DropdownMenuLabel>
                        {navigation.map((x: Link) => (
                            <Fragment key={x.to}>
                                <DropdownMenuSeparator />
                                <DropdownMenuGroup>
                                    <DropdownMenuItem>
                                        <NavLink to={x.to}
                                            className="group flex items-center gap-2">
                                            {x.icon}
                                            {x.text}
                                        </NavLink>
                                    </DropdownMenuItem>
                                </DropdownMenuGroup>
                            </Fragment>
                        ))}
                    </DropdownMenuContent>
                </DropdownMenu>
            </SidebarMenuItem>
        </SidebarMenu>
    )
}
