import { auth, logout } from "@/store/auth.slice";
import { storageLocal } from "@/lib/storage.utils";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

const API_URL = import.meta.env.VITE_API_URL || "http://127.0.0.1:8000";
const API_KEY = import.meta.env.VITE_API_KEY || "";
// console.log({ API_URL, API_KEY });

const getToken = (name: "access" | "refresh" = "access") => {
  // TODO read token from cookies or url parameters (for verify and reset)
  return storageLocal.getItem(name);
};

const setToken = (name: "access" | "refresh", token: string) => {
  // TODO read token from cookies or url parameters (for verify and reset)
  return storageLocal.setItem(name, token);
};

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  // credentials: 'include',
  // credentials: 'same-origin',
  prepareHeaders: (headers) => {
    headers.set("ApiKey", `${API_KEY}`);
    const token = getToken();
    if (token) {
      headers.set("Authorization", "Bearer " + token);
    }
    return headers;
  },
}) as unknown as BaseQueryFn<
  string | FetchArgs,
  unknown,
  { status: number; message: string | string[]; error: string }
>;

const baseQueryWithHeaders: BaseQueryFn<
  string | FetchArgs,
  unknown,
  { status: number; message: string | string[]; error: string }
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  // if access token is expired
  if (result.error && result.error.status === 401) {
    const refresh = getToken("refresh");
    if (refresh) {
      // set refresh token as access token to request new access token
      setToken("access", refresh);
      const refreshResult = await baseQuery(
        {
          url: "/auth/refresh",
          method: "POST",
        },
        api,
        extraOptions,
      );
      console.log({ refreshResult });
      if (refreshResult.data) {
        api.dispatch(auth(refreshResult.data));
        // retry the initial query
        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch(logout());
        document.location = "/login";
      }
    }
  }
  return result;
};

const baseApi = createApi({
  baseQuery: baseQueryWithHeaders,
  endpoints: () => ({}),
});

export { API_URL, baseApi };
