"use client"

import { ChevronRight } from "lucide-react"

import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from "@/components/ui/collapsible"
import {
    SidebarGroup,
    SidebarGroupLabel,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
    SidebarMenuSub,
    SidebarMenuSubButton,
    SidebarMenuSubItem,
} from "@/components/ui/sidebar"
import { useNavigation } from "@/hooks"
import { NavLink } from "react-router-dom"

export function NavMain() {


    const navigation = useNavigation("sidebar");

    return (
        <SidebarGroup>
            <SidebarGroupLabel>Platform</SidebarGroupLabel>
            <SidebarMenu>
                {navigation.map((x) => (
                    x.children ? <Collapsible
                        key={x.to}
                        asChild
                        defaultOpen={x.defaultOpen}
                        className="group/collapsible"
                    >
                        <SidebarMenuItem>
                            <CollapsibleTrigger asChild>
                                <SidebarMenuButton tooltip={x.text}>
                                    {x.icon}
                                    <span>{x.text}</span>
                                    <ChevronRight className="group-data-[state=open]/collapsible:rotate-90 ml-auto transition-transform duration-200" />
                                </SidebarMenuButton>
                            </CollapsibleTrigger>
                            <CollapsibleContent>
                                <SidebarMenuSub>
                                    {x.children?.map((y) => (
                                        <SidebarMenuSubItem key={y.to}>
                                            <SidebarMenuSubButton asChild>
                                                <NavLink to={y.to}>
                                                    <span>{y.text}</span>
                                                </NavLink>
                                            </SidebarMenuSubButton>
                                        </SidebarMenuSubItem>
                                    ))}
                                </SidebarMenuSub>
                            </CollapsibleContent>
                        </SidebarMenuItem>
                    </Collapsible>
                        :
                        <SidebarMenuItem key={x.to}>
                            <SidebarMenuButton tooltip={x.text} asChild>
                                <NavLink to={x.to} className="group-data-[collapsible=icon]:pl-1!">
                                    {x.icon}
                                    <span>{x.text}</span>
                                </NavLink>
                            </SidebarMenuButton>
                        </SidebarMenuItem>
                ))}
            </SidebarMenu>
        </SidebarGroup>
    )
}
