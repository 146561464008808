import { storageLocal, storageSession } from "@/lib/storage.utils";
import CustomError from "@/models/CustomError.model";
import type { RootState } from "@/store";
import { ErrorType } from "@/types/error.type";
import { MessageType } from "@/types/message.type";
import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "system",
  initialState: {
    status: "",
    errors: [] as ErrorType[],
    messages: [] as MessageType[],
    modals: [] as string[],
    data: storageLocal.getObject("data") as Record<
      string,
      string | number | boolean | undefined
    >,
    session: storageSession.getObject("session") as Record<
      string,
      string | number | boolean | undefined
    >,
  },
  reducers: {
    // setHide: (state, { payload }) => {
    //   const error = state.errors.find((i) => i.timestamp === payload.timestamp);
    //   if (error) {
    //     error.hidden = true;
    //   }
    //   state.errors = [...state.errors];
    //   // state.errors = [payload.data, ...state.errors.slice(0, 9)];
    // },
    pushMessage: (state, { payload }) => {
      state.messages.push(payload);
      state.messages = [...state.messages];
    },
    removeMessage: (state) => {
      state.messages.shift();
      state.messages = [...state.messages];
    },
    pushError: (state, { payload }) => {
      state.errors.push(new CustomError(payload).toObject());
      state.errors = [...state.errors];
    },
    clearErrors: (state) => {
      state.errors = state.errors.filter((i) => (i.hidden = true));
    },
    hideError: (state, { payload }) => {
      const error = state.errors.find(
        (i) => Number(i.timestamp) === Number(payload.timestamp),
      );
      if (error) {
        error.hidden = true;
      }
      state.errors = [...state.errors];
    },
    showModal: (state, { payload }) => {
      state.modals = [...state.modals, payload];
    },
    closeModal: (state) => {
      // console.log('closeModal', state.modals);
      state.modals.pop();
      state.modals = [...(state.modals || [])];
    },
    setData: (state, { payload }) => {
      state.data = { ...state.data, ...payload };
      storageLocal.setObject("data", state.data);
    },

    setSession: (state, { payload }) => {
      state.session = { ...state.session, ...payload };
      storageSession.setObject("session", state.session);
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state, { type, ...action }) => {
      if (type.match("pending")) state.status = "loading";
      else if (type.match("rejected")) state.status = "error";
      else if (type.match("fulfilled")) state.status = "";

      if (type.match("rejected")) {
        const { payload } = action as {
          payload: {
            status: number | string;
            data: CustomError;
            message: string;
            error: string;
          };
        };
        if (payload?.status && payload.status === "FETCH_ERROR") {
          if (state.errors[0]?.status === 503)
            state.errors[0]["hidden"] = false;
          else
            state.errors = [
              new CustomError(payload).toObject(),
              ...state.errors.slice(0, 9),
            ];
        } else if (payload.data) {
          const lastError = state.errors?.length
            ? state.errors[0]
            : ({} as CustomError);
          if (lastError.message !== payload.data?.message)
            state.errors = [
              new CustomError(payload.data).toObject(),
              ...state.errors.slice(0, 9),
            ];
        }
      }

      // console.log('error.slice', type, payload, meta);
      // state.name = payload.name;
      // state.roles = payload.roles;
      // state.accessToken = payload.accessToken;
      // state.refreshToken = payload.refreshToken;
      // // TODO write on cookies
      // localStorage.setItem('name', payload.name);
      // // localStorage.setItem('roles', payload.roles);
      // localStorage.setItem('accessToken', payload.accessToken);
      // localStorage.setItem('refreshToken', payload.refreshToken);
    });
    // builder.addMatcher(api.endpoints.authControllerLogout.matchFulfilled, (state) => {
    //   console.log('logout', { state });
    //   state.name = '';
    //   state.roles = [];
    //   state.accessToken = '';
    //   state.refreshToken = '';
    //   // TODO delete on cookies
    //   localStorage.clear();
    // });
  },
});

export const {
  // setHide,
  pushMessage,
  removeMessage,
  pushError,
  clearErrors,
  hideError,
  showModal,
  closeModal,
  setData,
  setSession,
} = slice.actions;

export default slice.reducer;

export const selectStatus = (state: RootState) => state.system.status;
export const selectErrors = (state: RootState) => state.system.errors;
export const selectMessages = (state: RootState) => state.system.messages;
export const selectModals = (state: RootState) => state.system.modals;
export const selectData = (state: RootState) => state.system.data;
export const selectSession = (state: RootState) => state.system.session;
// export const selectInitData = (state: RootState) => state.system.init;
