import { Icon } from "@/components/custom/icon.component";
import { Logo } from "@/components/custom/logo.component";
import { LocaleSwitcher } from "@/components/generic/locale-switcher.component";
import { ThemeSwitcher } from "@/components/generic/theme-switcher.component";
import { useNavigation } from "@/hooks";
import { useAuth } from "@/hooks/useAuth.hook";

import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";

interface Props {
  className: string;
}

export default function Header({ className = "" }: Props) {
  const { t } = useTranslation();
  const navigation = useNavigation("main");
  const [isOpen, setIsOpen] = useState(false);
  const { isAuthenticated, name } = useAuth();
  // const { id, closeModal } = useModal();

  return (
    <header
      className={`z-40 h-16 flex flex-wrap lg:justify-center lg:flex-nowrap w-full bg-white/80 shadow-xs border-b border-neutral-200/50 dark:bg-zinc-900/80 dark:border-b-black/80 text-sm lg:py-4 ${className}`}
    >
      <nav
        className="relative lg:flex lg:justify-between lg:items-center w-full h-full"
        aria-label={t("Global")}
      >
        <div className="z-40 relative flex justify-between items-center px-4 py-2 lg:py-0">
          <NavLink
            className="flex font-semibold text-neutral-700! text-xl"
            to="/"
            aria-label={t("Logo")}
          >
            <Logo className="h-10" />
          </NavLink>
          <div className="lg:hidden flex justify-center items-center gap-y-2">
            <button
              type="button"
              className="inline-flex justify-center items-center gap-2 p-2 rounded-md font-medium align-middle"
              aria-controls="navbar-collapse-with-animation"
              aria-label={t("Toggle navigation")}
              onClick={() => setIsOpen(!isOpen)}
            >
              <Icon>menu</Icon>
            </button>
          </div>
        </div>
        <div
          className={`grow overflow-x-auto flex flex-col lg:flex-row-reverse lg:items-center justify-between lg:gap-y-0  lg:mt-0 lg:pl-7
          bg-white dark:bg-neutral-950/95 lg:bg-transparent dark:lg:bg-transparent 
           transition-transform transform duration-200 text-lg lg:text-sm
          ${isOpen ? "" : "-translate-y-full -mt-16"} lg:translate-y-0`}
        >
          <div className="flex lg:flex-row-reverse justify-between lg:justify-start items-center gap-x-2 p-4">
            {isAuthenticated ? (
              <div>
                {t("Hi")},{" "}
                <Link to="/dashboard" className="text-blue-500">
                  {name}
                </Link>
              </div>
            ) : (
              <div className="flex gap-x-4">
                <NavLink
                  className="flex items-center gap-x-2 font-medium text-neutral-500 hover:text-blue-600 whitespace-nowrap"
                  to="/login"
                  onClick={() => setIsOpen(false)}
                >
                  <Icon>login</Icon>  {t('Log in')}
                </NavLink>
                <NavLink
                  className="flex items-center gap-x-2 font-medium text-neutral-500 hover:text-blue-600"
                  to="/register"
                  onClick={() => setIsOpen(false)}
                >
                  <Icon>person</Icon> {t('Register')}
                </NavLink>
              </div>
            )}
            {/*{isAuthenticated && (*/}
            {/*  <Link to='/settings' className='text-blue-500' onClick={() => setIsOpen(false)}>*/}
            {/*    <i className='icon-cog' /> {t('Settings')}*/}
            {/*  </Link>*/}
            {/*)}*/}
            <div className={"flex items-center pl-2"}>
              <ThemeSwitcher />
              <LocaleSwitcher short={false} className="mx-2" />
            </div>
          </div>
          <div className="flex lg:flex-row flex-col">
            {navigation.map(({ text, to, ...i }, k) => (
              <NavLink
                key={k}
                to={to}
                className="px-4 py-3 lg:border-0 dark:border-white/10 border-t border-black/10"
                onClick={() => setIsOpen(false)}
              >
                {i?.icon && i.icon}
                {text}
              </NavLink>
            ))}
            {/*<button onClick={() => openModal()}>Show login modal</button>*/}

            {isAuthenticated && isOpen && (
              <Link
                to="/logout"
                className="px-4 py-3 lg:border-0 dark:border-white/10 border-t border-black/10 text-red-500!"
              >
                <Trans
                  i18nKey="Logout <i/>"
                  components={{
                    i: <Icon>logout</Icon>,
                  }}
                />
              </Link>
            )}
          </div>
          {/*<Avatar.Placeholder/>*/}
        </div>
      </nav>
      {/*<Modal id={id}>*/}
      {/*  <LoginView />*/}
      {/*</Modal>*/}
      {/*<Modal id='login2'>*/}
      {/*  <>*/}
      {/*    LOGIN 2<button onClick={closeModal}>CLOSE MODAL</button>*/}
      {/*  </>*/}
      {/*</Modal>*/}
      {/*<Modal open={open} handleClose={() => setOpen(false)}>*/}
      {/*  <LoginView />*/}
      {/*</Modal>*/}
    </header>
  );
}
