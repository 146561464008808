const PREFIX = import.meta.env.VITE_PROJECT;
const specifyKey = (key: string) => `${PREFIX}-${key}`;
export const storageLocal = {
  setItem: (key: string, value: string) =>
    localStorage.setItem(specifyKey(key), value),
  getItem: (key: string) => localStorage.getItem(specifyKey(key)),
  setObject: function <T>(key: string, value: T) {
    storageLocal.setItem(key, JSON.stringify(value));
  },
  getObject: function <T>(key: string): T {
    return JSON.parse(String(storageLocal.getItem(key))) as T;
  },
  removeItem: (key: string) => localStorage.removeItem(specifyKey(key)),
};
export const storageSession = {
  setItem: (key: string, value: string) =>
    sessionStorage.setItem(specifyKey(key), value),
  getItem: (key: string) => sessionStorage.getItem(specifyKey(key)),
  setObject: function <T>(key: string, value: T) {
    storageSession.setItem(key, JSON.stringify(value));
  },
  getObject: function <T>(key: string): T {
    return JSON.parse(String(storageSession.getItem(key))) as T;
  },
  removeItem: (key: string) => sessionStorage.removeItem(specifyKey(key)),
};
