import { connect } from "react-redux";
import { RootState } from "@/store";
import { selectStatus } from "@/store/system.slice";

const classes = (status: string): string =>
  ({
    loading: `bg-linear-to-r from-rose-400 
    via-fuchsia-500 to-indigo-500 animate-system-loading`,
    error: "bg-red-500/75",
  })[status] || "";

function Loader({ status = "default" }: { status: string }) {
  return (
    <div
      className={`fixed top-0 left-0 w-full z-50 h-1 ${classes(status)}`}
      style={{ backgroundSize: "200% 400%" }}
    />
  );
}

export default connect((state: RootState) => ({
  status: selectStatus(state),
}))(Loader);
