import { View } from "@/components/generic/view.component";
import { useAccount } from "@/hooks/useAccount.hook";
import { useAuth } from "@/hooks/useAuth.hook";
import { SecurityMessages } from "@/layout/components/security-messages.component.tsx";
import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Navigate, Outlet, useLocation } from "react-router-dom";

type Props = {
  check?: (roles: string[]) => boolean;
  fallback?: ReactElement;
};

export function SecureLayout({ check = () => true, fallback }: Props) {
  // const navigate = useNavigate();
  const location = useLocation();

  const { isAuthenticated, roles } = useAuth();
  console.log('roles', { roles })
  if (roles && roles.includes("newbie")) {
    if (!location.pathname.match("verify")) {
      console.log('navigate to verify', location.pathname.match("verify"))
      // navigate("/verify");
    }
    // TODO: add initilaize layout  to  load defaults and check role based instructions
    // can extend to show tooltips or other instructions based on role
  }
  const { isLoading, isError, error, initialize } = useAccount();
  const hasPass = check(roles);
  const { t } = useTranslation();
  useEffect(() => {
    if (!isLoading && !isError) initialize();
  }, [initialize]);

  return <View isLoading={isLoading} isError={isError} error={error} loader={<div className="flex flex-col justify-center items-center w-full h-full">
    {t('Account data initializing...')}
  </div>}>
    {!isAuthenticated ? (
      <Navigate to="/login" state={{ from: location }} />
    ) : hasPass ? (
      <Outlet />
    ) : (
      fallback || <SecurityMessages.NoAccess />
    )}
  </View>
}
